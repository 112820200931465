<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Module Handover</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <router-link :to="{ name: 'module-handover-create' }">
                <button
                  v-show="!loading"
                  type="button"
                  class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                  v-if="hasPermissions(['module-group-create'])"
                >
                  <i class="mdi mdi-plus-circle"></i> Add Handover
                </button>
              </router-link>
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="handover-list-datatable"
                v-show="!loading"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Issued Date</th>
                    <th>Trainer</th>
                    <th v-if="hasPermissions(['module-group-edit'])">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(moduleHandover, index) in filterHandoverLists"
                    :key="index"
                  >
                    <td>{{ ++index }}</td>
                    <td>{{ moduleHandover.issued_date }}</td>
                    <td>{{ moduleHandover.selected_trainer.name }}</td>
                    <td v-if="hasPermissions(['module-group-edit'])">
                      <span>
                        <router-link
                          class="action-icon"
                          :to="{
                            name: 'module-handover-detail',
                            params: { id: moduleHandover.id },
                          }"
                        >
                          <button
                            type="button"
                            class="btn btn-xs btn-blue waves-effect waves-light mb-2"
                          >
                            <i class="fas fa-eye"></i>
                            View
                          </button>
                        </router-link>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable moduleHandover
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      handoverLists: [],
      current_user_id: this.$store.state.auth.user.employee_id,
      filterHandoverLists: [],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllModuleHandover() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/module-handover-lists`)
        .then((response) => {
          this.handoverLists = response.data.data;
          if (
            this.$store.state.auth.user.roles[0] == "Training Head" ||
            this.$store.state.auth.user.roles[0] == "Super Admin"
          ) {
            this.filterHandoverLists = this.handoverLists;
          } else {
            this.handoverLists.map((item) => {
              if (item.selected_trainer.employee_id == this.current_user_id) {
                this.filterHandoverLists.push(item);
              }
            });
          }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#handover-list-datatable").DataTable();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#handover-list-datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAllModuleHandover();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
